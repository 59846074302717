'use strict';

// App config the for development environment.
export default {
     app: {
        host: 'http://localhost:3000',
        port:'3000',
        apiversion: ""
    },
    api: {
        //host: 'https://cnsdrive.com/cap/api/shop/v1',
        //host: 'https://cnsd26.sg-host.com/api/shop/v1',
        host : 'https://2024.customizeaproduct.com/api/shop/v1',
        port:'5000',
        apiversion: ""
    },
    api1: {
        //host: 'https://cap-api3.cns72.com',
        //host: 'https://cap-api.cnsfly.com',
        host : 'https://backend.customizeaproduct.com',
        port:'8012',
        apiversion: ""
    },
    // app: {
    //     host: 'https://lamcraft-front.cns72.com',
    //     port:'7050',
    //     apiversion: ""
    // },
    // api: {
    //     host: 'https://lamcraft-back.cns72.com',
    //     port:'7060',
    //     apiversion: ""
    // },
};